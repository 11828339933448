.manageSenderDetails {
  color: rgba(115, 101, 205, 1);
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 0;
}

.sectionTitle {
  color: rgba(39, 37, 34, 0.8);
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
}

.desktopModalPaper {
  min-width: 600px;
}
